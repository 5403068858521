import React, {useEffect,useState} from 'react';

import { Box,Button, Divider,FormControl,FormHelperText,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import { useConfirm } from "material-ui-confirm";
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import SimpleModal from '../layout/SimpleModal';
import { createNotifySysAsync, deleteNotifySysAsync, getAsyncNotifySysList, updateNotifySysAsync, updateNotifyUser } from '../../store/actions/main/NotifyAction';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
const commonUtil = require('../../utils/CommonUtil')
const {sysNotifyTypeList, statusList} = require('../../utils/SysData')

const NotifySysList = (props) => {
    const confirm = useConfirm();

    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [typeId,setTypeId] = useState("")
    const [status,setStatus] = useState("")
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const [notifySysObj,setNotifySysObj] = useState({})
    const [notifySysArray,setNotifySysArray] = useState([])

    const [modalOpen, setModalOpen] = useState(false);
    const [newModal, setNewModal] = useState(true);
    const [validation,setValidation] = useState({});

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        console.log(data)
        setModalOpen(true)
        if(data == null){
            setNewModal(true)
            setNotifySysObj({})
        }else{
            setNewModal(false)
            setNotifySysObj(data)
        }
    }
    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize, typeId,status,            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncNotifySysList(queryObj);
        if(res && res.list){
            setNotifySysArray([...res.list])
            setTotal(res.total)
        }
    }

    const validate = ()=>{
        const validateObj ={};
        
        if (!notifySysObj.title || notifySysObj.title.length==0) {
            validateObj.title ='请输入消息标题';
        }
        if (!notifySysObj.content || notifySysObj.content.length==0) {
            validateObj.content ='请输入消息内容';
        }
        if (!notifySysObj.typeId) {
            validateObj.typeId ='请选择消息类型';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const save = ()=> {
        const errorCount = validate();
        if(errorCount===0){
            setModalOpen(false);
            newModal?createNotifySys():updateNotifySys()
        }
    }
    const reset = () => {
        setStatus();
        setTypeId();
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const createNotifySys = async()=> {
        const res = await createNotifySysAsync(notifySysObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)
        }else {
            ErrorAlert("操作失败")
        }
       
    }

    const updateNotifySys = async()=>{        
        const res = await updateNotifySysAsync(notifySysObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    const updateStatus = async(i) => {
        const statusObj = {
            id : notifySysArray[i].id,
            status: notifySysArray[i].status == 1? 0:1
        }
        const res = await updateNotifySysAsync(statusObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    const deleteNotifySys = async(id)=>{
        const paramObj = {id}
        const res = await deleteNotifySysAsync(paramObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    const remove = (id) => {
        confirm({ title:"删除信息",description: "确定要删除本条记录?",confirmationText:"确定",cancellationText:"取消"})
        .then(() =>{
            deleteNotifySys(id);
        }).catch(() => {
            console.log("取消!");
        });
    }

    useEffect(()=>{
        query(pageNumber)
    },[])
    return (
        
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >系统消息</Typography>
            <Divider  style={{borderWidth:2}} />

            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >类型</InputLabel>
                                <Select label="类型"
                                        value={typeId}
                                        onChange={(event, value) => {
                                            setTypeId(event.target.value);
                                        }}
                                >
                                    <MenuItem value="">请选择</MenuItem>
                                    {sysNotifyTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >消息状态</InputLabel>
                                <Select label="消息状态"
                                    value={status}
                                    onChange={(event, value) => {
                                        setStatus(event.target.value);                                        
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {statusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                                <IconButton color="primary" aria-label="reset" component="label" onClick={reset} >
                                    <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                                </IconButton>
                        </Tooltip>
                        <IconButton color="primary" aria-label="add" onClick={() => {openModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                        <IconButton color="primary" aria-label="query" component="label" onClick={() => {query()}} >
                            <Icon path={md["mdiMagnify"]} size={1.5}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">类型</TableCell>
                                <TableCell  align="center">标题</TableCell>
                                <TableCell  align="center">创建日期</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifySysArray.length > 0 && notifySysArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(sysNotifyTypeList, row.typeId)}</TableCell>
                                    <TableCell align="center">{row.title}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                        <Switch checked={row.status == 1} onChange={()=>{updateStatus(index)}}/>
                                        <IconButton   aria-label="delete" component="label" onClick={() => {remove(row.id)}} >
                                            <Icon path={md["mdiDeleteForever"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { notifySysArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {notifySysArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }

            <SimpleModal
                maxWidth={'sm'}
                title={ newModal ? '新增系统消息' : '修改系统消息'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    {!newModal && <Grid item sm={12}><Typography color="primary">ID：{notifySysObj.id}</Typography></Grid>}
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel>类型</InputLabel>
                            <Select label="类型"
                                value={notifySysObj.typeId}
                                onChange={(event, value) => {
                                    setNotifySysObj({...notifySysObj,typeId:event.target.value});
                                }}
                                error={validation.typeId&&validation.typeId!=''}
                            >
                                {sysNotifyTypeList.map((item, index) => (
                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                            {(validation.typeId&&validation.typeId!='') && <FormHelperText style={{color: 'red'}}>{validation.typeId}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="标题" fullWidth margin="dense" variant="outlined" value={notifySysObj.title}
                                   onChange={(e) => {
                                        setNotifySysObj({...notifySysObj,title:e.target.value});
                                   }}
                                   error={validation.title&&validation.title!=''}
                                   helperText={validation.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="内容" fullWidth 
                            variant="outlined"  multiline
                            maxRows={4} value={notifySysObj.content}
                            onChange={(e) => {
                                    setNotifySysObj({...notifySysObj,content:e.target.value});
                            }}
                            error={validation.content&&validation.content!=''}
                            helperText={validation.content}
                        />
                    </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}


export default NotifySysList;