import React, {useEffect,useState} from 'react';
import {connect,useDispatch} from 'react-redux';
import {Link} from "react-router-dom";

import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncBizList } from '../../store/actions/main/BizListAction';
import { getAsyncBaseProvidence,getAsyncBaseCity } from '../../store/actions/layout/BaseAction';
import { scaleTypeList,bizTypeList,restTypeList } from '../../utils/SysData';
import * as commonUtil from '../../utils/CommonUtil';
import { BizListActionType } from '../../store/types';

const BizList = (props) => {
    const dispatch = useDispatch();
    const {bizListReducer} = props;
    
    const [bizList,setBizList] = useState([])
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [providenceList,setProvidenceList] = useState([])
    const [cityList,setCityList] = useState([])

    const [bizShortName,setBizShortName] = useState()
    const [bizName,setBizName] = useState()
    const [bizOrgId,setBizOrgId] = useState()
    const [scaleType,setScaleType] = useState("")
    const [restType,setRestType] = useState("")
    const [bizType,setBizType] = useState("")
    const [providenceId,setProvidenceId] = useState(0)
    const [cityId,setCityId] = useState(0)
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const getBizInfo = async () => {

        const queryObj = {...bizListReducer.queryParams}
        if(queryObj.createdStart != null && queryObj.createdStart != ""){
            queryObj.createdStart = queryObj.createdStart+ "T00:00:00Z"
        }
       
        if(queryObj.createdEnd != null && queryObj.createdEnd != ""){
            queryObj.createdEnd = queryObj.createdEnd+ "T23:59:59Z"
        }
       
        const res = await getAsyncBizList(queryObj);
        if(res && res.list){
            setBizList([...res.list])
            setTotal(res.total)
        }
    }
    const reset =()=>{
       
        dispatch(BizListActionType.resetBizListParams({}));
        
    }
    const changePage = (i)=>{
        setPageNumber(i)
        dispatch(BizListActionType.setBizListParams({name: "pageNumber", value: i}))
        getBizInfo()
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }
    useEffect(()=>{
        getBizInfo(pageNumber);
        getProvidence();
        if(bizListReducer.queryParams.providenceId != null &&bizListReducer.queryParams.providenceId >0){
            getCity({pid:bizListReducer.queryParams.providenceId})
        }
    },[])


    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >企业信息</Typography>
            <Divider  style={{borderWidth:2}} />
            {/* <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizListReducer.queryParams.bizShortName}
                                onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "bizShortName", value: e.target.value}))}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizListReducer.queryParams.bizName}
                                onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "bizName", value: e.target.value}))}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="组织机构代码"
                                value={bizListReducer.queryParams.bizOrgId}
                                onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "bizOrgId", value: e.target.value}))}
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >企业规模</InputLabel>
                                <Select label="企业规模"
                                    value={bizListReducer.queryParams.scaleType}
                                    onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "scaleType", value: e.target.value}))}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {scaleTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >休息方式</InputLabel>
                                <Select label="休息方式"
                                    value={bizListReducer.queryParams.restType}
                                    onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "restType", value: e.target.value}))}>
                                    
                                    <MenuItem value="">请选择</MenuItem>
                                    {restTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >企业性质</InputLabel>
                                <Select label="企业性质"
                                    value={bizListReducer.queryParams.bizType}
                                    onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "bizType", value: e.target.value}))}>
                                    
                                    <MenuItem value="">请选择</MenuItem>
                                    {bizTypeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={bizListReducer.queryParams.providenceId}
                                    onChange={(e)=>{
                                        dispatch(BizListActionType.setBizListParams({name: "providenceId", value: e.target.value}))
                                        getCity({pid:e.target.value})
                                    }}>                                    
                                    
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={bizListReducer.queryParams.cityId}
                                    onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "cityId", value: e.target.value}))}>
                                    
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="入驻开始日期" value={bizListReducer.queryParams.createdStart} 
                                onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "createdStart", value: e.format('YYYY-MM-DD')}))}
                                disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="入驻截止日期"  value={bizListReducer.queryParams.createdEnd} 
                                onChange={(e)=>dispatch(BizListActionType.setBizListParams({name: "createdEnd", value: e.format('YYYY-MM-DD')}))}
                                disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);getBizInfo(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">公司简称</TableCell>
                                <TableCell  align="center">组织机构代码</TableCell>
                                <TableCell  align="center">所在地区</TableCell>
                                <TableCell  align="center">成立日期</TableCell>
                                <TableCell  align="center">企业性质</TableCell>
                                <TableCell  align="center">企业规模</TableCell>
                                <TableCell  align="center">休息方式</TableCell>
                                <TableCell  align="center">入驻时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   bizList && bizList.length>0 && bizList.map((row,index) => (
                                <TableRow key={"biz_info_"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.bizShortName}</TableCell>
                                    <TableCell align="center" >{row.bizOrgId}</TableCell>
                                    <TableCell align="center" >{row.providenceName}-{row.cityName}-{row.areaName}</TableCell>
                                    <TableCell align="center" >{row.publishOn}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(bizTypeList,row.bizType)}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(scaleTypeList,row.scaleType)}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(restTypeList,row.restType)}</TableCell>
                                    <TableCell align="center" >{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center" >
                                        <Link to={{pathname: '/biz_list/' + row.id}}>
                                            <IconButton  color="primary" aria-label="edit" component="label" onClick={()=>{}}>
                                                <Icon path={md["mdiReceiptTextCheckOutline"]} size={1}/>
                                            </IconButton>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                            { bizList.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {bizList.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={bizListReducer.queryParams.pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/bizListReducer.queryParams.pageSize)} color="primary" />
                </Grid>
            } */}
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        bizListReducer : state.BizListReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizList);