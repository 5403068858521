import React, {useEffect,useRef,useState} from 'react';
import {connect} from 'react-redux';

import { Autocomplete ,Box,Button, CircularProgress,Divider,FormControl,FormHelperText,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import { useConfirm } from "material-ui-confirm";
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';


import SimpleModal from '../layout/SimpleModal';
import { createNotifyUserAsync, deleteNotifyUserAsync, getAsyncNotifyUserList, updateNotifyUserAsync } from '../../store/actions/main/NotifyAction';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
import { getAsyncUserList } from '../../store/actions/main/UserListAction';
const commonUtil = require('../../utils/CommonUtil')
const { sysNotifyTypeList,readStatusList, genderOpts} = require('../../utils/SysData')

const NotifyUserList = (props) => {
    const confirm = useConfirm();

    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [userList,setUserList] = useState([])
    const [newUserList,setNewUserList] = useState([])
    const [phone,setPhone] = useState([])
    const [newPhone,setNewPhone] = useState([])
    
    const [userId,setUserId] = useState("")
    const [status,setStatus] = useState("")
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const [notifyUserObj,setNotifyUserObj] = useState({})
    const [notifyUserArray,setNotifyUserArray] = useState([])

    const [queryAutoLoad ,setQueryAutoLoad] = useState(false)
    const [addAutoLoad ,setAddAutoLoad] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [newModal, setNewModal] = useState(true);
    const [validation,setValidation] = useState({});


    const queryAutoRef = useRef()
    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        console.log(data)
        setModalOpen(true)
        if(data == null){
            setNewModal(true)
            setNotifyUserObj({})
        }else{
            setNewModal(false)
            setNotifyUserObj(data)
        }
    }
    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize, userId,status,            
        }
        console.log(userId)
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncNotifyUserList(queryObj);
        if(res && res.list){
            setNotifyUserArray([...res.list])
            setTotal(res.total)
        }
    }
    const queryUserList = async(paramObj,newFlag) => {
        const res = await getAsyncUserList(paramObj)
        if(res && res.list){
            if(newFlag){
                setNewUserList(res.list)
            }else{
                setUserList(res.list)
            }
        }
        setQueryAutoLoad(false)
        setAddAutoLoad(false)
    }
    const validate = ()=>{
        const validateObj ={};
        
        if (!notifyUserObj.title || notifyUserObj.title.length==0) {
            validateObj.title ='请输入消息标题';
        }
        if (!notifyUserObj.content || notifyUserObj.content.length==0) {
            validateObj.content ='请输入消息内容';
        }
        if (!notifyUserObj.userId) {
            validateObj.userId ='请选择发送对象';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }

    useEffect(() => {
        if(phone && phone.length>3){
            setQueryAutoLoad(true)
            queryUserList({phone})
        }else{
            setUserList([])
        }
    }, [phone]);

    useEffect(() => {
        if(newPhone && newPhone.length>3){
            setAddAutoLoad(true)
            queryUserList({phone:newPhone},true)
        }else{
            setUserList([])
        }
    }, [newPhone]);

    const save = ()=> {
        const errorCount = validate();
       
        if(errorCount===0){
            setModalOpen(false);
            newModal?createNotifyUser():updateNotifyUser()
        }
    }
    const reset = () => {
        setStatus();
        setUserId();
        setPhone();
        const ele = queryAutoRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) ele.click();
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const createNotifyUser = async()=> {
        const res = await createNotifyUserAsync(notifyUserObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)
        }else {
            ErrorAlert("操作失败")
        }
       
    }

    const updateNotifyUser = async()=>{        
        const res = await updateNotifyUserAsync(notifyUserObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    
    const deleteNotifyUser = async(id)=>{
        const paramObj = {id}
        const res = await deleteNotifyUserAsync(paramObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    const remove = (id) => {
        confirm({ title:"删除信息",description: "确定要删除本条记录?",confirmationText:"确定",cancellationText:"取消"})
        .then(() =>{
            deleteNotifyUser(id);
        }).catch(() => {
            console.log("取消!");
        });
    }

    useEffect(()=>{
        query(pageNumber)
    },[])
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >用户消息{phone}</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel ></InputLabel>
                                <Autocomplete 
                                    ref={queryAutoRef}
                                    loading={queryAutoLoad}
                                    options={userList}
                                    onChange={(o,i)=>{console.log("change");i !=null ?setUserId(i.id):setUserId("")}}
                                    getOptionLabel={(option) => option.phone+"-"+option.name}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="用户手机号"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                  {queryAutoLoad ? <CircularProgress color="inherit" size={20} /> : null}
                                                  {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                        value={phone}                                        
                                        />
                                    )}
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >消息状态</InputLabel>
                                <Select label="消息状态"
                                    value={status}
                                    onChange={(event, value) => {
                                        setStatus(event.target.value);                                        
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {readStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                                <IconButton color="primary" aria-label="reset" component="label" onClick={reset} >
                                    <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                                </IconButton>
                        </Tooltip>
                        <IconButton color="primary" aria-label="add" onClick={() => {openModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                        <IconButton color="primary" aria-label="query" component="label" onClick={() => {query()}} >
                            <Icon path={md["mdiMagnify"]} size={1.5}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">用户ID</TableCell>
                                <TableCell  align="center">姓名</TableCell>
                                <TableCell  align="center">电话</TableCell>
                                <TableCell  align="center">性别</TableCell>
                                <TableCell  align="center">标题</TableCell>
                                <TableCell  align="center">创建日期</TableCell>
                                <TableCell  align="center">消息状态</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifyUserArray.length > 0 && notifyUserArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{row.userId}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.phone}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(genderOpts, row.gender)}</TableCell>
                                    <TableCell align="center">{row.title}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">{commonUtil.getJsonValue(readStatusList, row.status)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                        
                                        <IconButton   aria-label="delete" component="label" onClick={() => {remove(row.id)}} >
                                            <Icon path={md["mdiDeleteForever"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { notifyUserArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {notifyUserArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }

            <SimpleModal
                maxWidth={'sm'}
                title={ newModal ? '新增系统消息' : '修改系统消息'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    {!newModal && <Grid item sm={12}><Typography color="primary">ID：{notifyUserObj.id}</Typography></Grid>}
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel></InputLabel>
                            {newModal?<Autocomplete 
                                    loading={addAutoLoad}
                                    options={newUserList}
                                    onChange={(o,i)=>{i !=null ?setNotifyUserObj({...notifyUserObj,userId:i.id}):setNotifyUserObj({...notifyUserObj,userId:null})}}
                                    getOptionLabel={(option) => option.phone+"-"+option.name}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="用户手机号"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                  {addAutoLoad ? <CircularProgress color="inherit" size={20} /> : null}
                                                  {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        onChange={(e) => {
                                            setNewPhone(e.target.value);
                                        }}
                                        value={newPhone}                                        
                                        />
                                    )}
                            /> :
                            <TextField label="用户手机号" disabled fullWidth margin="dense" variant="outlined" value={`${notifyUserObj.phone}-${notifyUserObj.name}`}
                                   
                            />
                            }
                            {(validation.userId&&validation.userId!='') && <FormHelperText style={{color: 'red'}}>{validation.userId}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="标题" fullWidth margin="dense" variant="outlined" value={notifyUserObj.title}
                                   onChange={(e) => {
                                        setNotifyUserObj({...notifyUserObj,title:e.target.value});
                                   }}
                                   error={validation.title&&validation.title!=''}
                                   helperText={validation.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="内容" fullWidth 
                            variant="outlined"  multiline
                            maxRows={4} value={notifyUserObj.content}
                            onChange={(e) => {
                                    setNotifyUserObj({...notifyUserObj,content:e.target.value});
                            }}
                            error={validation.content&&validation.content!=''}
                            helperText={validation.content}
                        />
                    </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(NotifyUserList);