import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import { Avatar,Badge,Box, Chip, Divider,FormControl,Grid,IconButton,ImageList,ImageListItem,
    Stack,Tab, Tabs, Tooltip,Typography} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import {Link, useParams } from 'react-router-dom';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs'

import { fileHost } from '../../config';
import { getAsyncBizList } from '../../store/actions/main/BizListAction';
import { getAsyncUserList } from '../../store/actions/main/UserListAction';
import { getDateTime,getJsonValue,getYearMonthDate } from '../../utils/CommonUtil';
import { bizTypeList, restTypeList, scaleTypeList } from '../../utils/SysData';
const sysAlert = require('../../utils/SysAlert');

const BizListDetail = (props) => {
    const {bizId} = useParams();
    
    const [tabIndex,setTabIndex] = useState(0)
    const [bizInfo,setBizInfo] = useState({})
    const [userInfo,setUserInfo] = useState({})

    const openImageView = (domId) => {
        const viewer = new Viewer(document.getElementById(domId), {
            modal: true,
            viewed() {
              viewer.zoomTo(1);
            },
        });
    }
    const getBizInfo = async (bizId) => {
        const queryObj ={
            id:bizId    
        }        
        const res = await getAsyncBizList(queryObj);
        if(res && res.list && res.list.length>0){
            setBizInfo(res.list[0])
            
            const userRes = await getAsyncUserList({id:bizInfo.userId})
            if(userRes && userRes.list && userRes.list.length>0){
                setUserInfo(userRes.list[0])
            }
        }else{
            sysAlert.WarningAlert("获取企业信息失败")
        }
    }

    const showImageList = ( )=>{
        const gallery = new Viewer(document.getElementById('biz_img_list'));
       
    }
    useEffect(()=>{
        getBizInfo(bizId)
        
    },[])
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >
                <Link to={{pathname: '/biz_list', state: {fromDetail: true}}}>
                    <IconButton  color="primary" aria-label="edit" component="label" onClick={()=>{}}>
                        <Icon path={md["mdiArrowLeftBoldCircle"]} size={1.5}/>
                    </IconButton>
                </Link>
                企业详情-{bizId}
            </Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center"}}>
                {bizInfo.avatar && bizInfo.avatar !='' && 
                    <Avatar src={"http://"+fileHost+ bizInfo.avatar} variant="square" sx={{ width: 96, height: 96}}   />}
                <span style={{marginLeft:"1rem"}}>  
                    <Typography variant="h6" gutterBottom >{bizInfo.bizShortName}</Typography>
                    <Typography variant="h6" gutterBottom >{bizInfo.bizName}</Typography>
                </span>
                </Grid>
                <Grid item xs={12} xl={6} md={6} style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                <span style={{marginRight:"1rem"}}>  
                    <Typography variant="h6" style={{textAlign:"right"}} gutterBottom >{userInfo.name}-{bizInfo.userTitle}</Typography>
                    <Typography variant="h6" style={{textAlign:"right"}} gutterBottom >{userInfo.phone}</Typography>
                </span>
                {userInfo.avatar && userInfo.avatar !='' && 
                    <Avatar src={"http://"+fileHost+ userInfo.avatar}  sx={{ width: 64, height: 64}}   />}
                </Grid>
            </Grid>
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item xs={12} xl={4} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                    <Icon path={md["mdiCity"]} size={1.5}/>
                    <span style={{marginLeft:4}}>{bizInfo.providenceName}-{bizInfo.cityName}-{bizInfo.areaName}</span>
                </Grid>
                <Grid item xs={12} xl={4} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}> 
                <Icon path={md["mdiMapMarkerOutline"]} size={1.5}/>
                    <span style={{marginLeft:4}}>{bizInfo.address}</span>
                </Grid>
                <Grid item xs={12} xl={4} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}> 
                    <Icon path={md["mdiPoliceBadgeOutline"]} size={1.5}/>
                    <span style={{marginLeft:4}}>{bizInfo.bizOrgId}</span>
                </Grid>
            </Grid>
            <Divider style={{marginTop:"1rem"}}/>
            <Tabs
                value={tabIndex}
                onChange={(e,i)=>{setTabIndex(i)}}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                >
                <Tab label="认证信息" />
                <Tab label="基本信息" />
            </Tabs>
            <SwipeableViews     index={tabIndex} >
            <div  container="true" value={tabIndex} index={0} style={{marginTop:"2rem"}}>
                <Grid container  spacing={1} style={{paddingTop:10}}>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="认证时间">
                            <Icon path={md["mdiCertificateOutline"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{getDateTime(bizInfo.UpdatedAt)}</span>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="成立日期">
                            <Icon path={md["mdiCalendarCheckOutline"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{getYearMonthDate(bizInfo.publishOn)}</span>                        
                    </Grid>
                </Grid>
                <Grid container  spacing={1} style={{paddingTop:10}}>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="负责人">
                            <Icon path={md["mdiCalendarAccount"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{bizInfo.master}</span>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="负责人身份证">
                            <Icon path={md["mdiCardAccountDetails"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{bizInfo.masterId}</span>                        
                    </Grid>
                </Grid>
                <Grid  spacing={2} sx={{marginRight:"1rem"}} container alignItems="center" justifyContent={"center"}>
                    <Grid xs={6} item>
                        <Stack style={{margin:4}}>
                            <p>营业执照:</p>
                            <img id="licImage" onClick={()=>{openImageView("licImage")}} style={{objectFit: "cover", maxHeight: 400}} src={"http://"+fileHost+ bizInfo.licUrl} ></img>
                        </Stack>
                    </Grid>
                    <Grid xs={6} item>
                        <Stack style={{margin:4}}>
                            <p>授权书:</p>
                            <img id="introImage" onClick={()=>{openImageView("introImage")}}  style={{objectFit: "cover", maxHeight: 400}} src={"http://"+fileHost+ bizInfo.introUrl} ></img>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid style={{marginBottom:"2rem"}} spacing={2} container alignItems="center" justifyContent={"center"}>
                    <Grid xs={6} item>
                        <Stack style={{margin:4}}>
                            <p>负责人身份证正面:</p>
                            <img id="masterImage" onClick={()=>{openImageView("masterImage")}} style={{objectFit: "cover", maxHeight: 400}} src={"http://"+fileHost+ bizInfo.masterIdUrl} ></img>
                        </Stack>
                    </Grid>
                    <Grid xs={6} item>
                        <Stack style={{margin:4}}>
                            <p>负责人身份证反面:</p>
                            <img id="masterReImage" onClick={()=>{openImageView("masterReImage")}} style={{objectFit: "cover", maxHeight: 400}} src={"http://"+fileHost+ bizInfo.masterIdReUrl} ></img>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
            <div value={tabIndex} index={0} style={{marginTop:"2rem"}}>
                <Grid container spacing={1} style={{paddingTop:10}}>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="工作时间">
                            <Icon path={md["mdiHumanGreetingVariant"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{bizInfo.workStart}-{bizInfo.workEnd}</span>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="企业规模">
                            <Icon path={md["mdiOfficeBuildingOutline"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{getJsonValue(scaleTypeList,bizInfo.scaleType)}</span>                        
                    </Grid>
                </Grid>
                <Grid container  spacing={1} style={{paddingTop:10}}>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="公司性质">
                            <Icon path={md["mdiWebBox"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{getJsonValue(bizTypeList,bizInfo.bizType)}</span>
                    </Grid>
                    <Grid item xs={12} xl={6} md={6} style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="休息方式">
                            <Icon path={md["mdiBedClock"]} size={1.5}/>
                        </Tooltip>    
                        <span style={{marginLeft:4}}>{getJsonValue(restTypeList,bizInfo.restType)}</span>                        
                    </Grid>
                </Grid>
                <Grid container  spacing={1} style={{paddingTop:10}}>
                    <Grid item style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="企业福利">
                            <Icon path={md["mdiDiamondStone"]} size={1.5}/>
                        </Tooltip>  
                        {bizInfo.welfare && bizInfo.welfare.length>0 && bizInfo.welfare.map((item,index)=>{                        
                            return (<Chip key={'_biz_welfare_tag_'+index} sx={{margin:1}} color="primary" label={item.name}></Chip>
                        )})}
                    </Grid>                
                </Grid>
                <Grid container  spacing={1} style={{paddingTop:10}}>
                    <Grid item style={{display:"flex",alignItems:"center",color:"#666"}}>
                        <Tooltip title="企业介绍">
                            <Icon path={md["mdiTextLong"]} size={1.5}/>
                        </Tooltip>  
                        <Typography>{bizInfo.bizDesc}</Typography>
                    </Grid>                
                </Grid>
                <div   spacing={1} style={{paddingTop:10}}>
                    <ImageList id="biz_img_list" sx={{ width: "100%" }} variant="quilted" cols={6} rowHeight={164} >
                        {(bizInfo.img == null || bizInfo.img.length ==0) &&
                            <ImageListItem >
                                <img
                                    style={{objectFit:"cover"}}
                                    src={'/none_image.png'}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        }
                        {bizInfo.img && bizInfo.img.length>0 && bizInfo.img.map((item,index) => (
                            <ImageListItem key={"_biz_img_list_"+index} onClick={showImageList}>
                                <img
                                    style={{objectFit:"cover"}}
                                    src={`http://${fileHost}${item}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
            </div>
            </SwipeableViews>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizListDetail);