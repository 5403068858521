import React, {useState} from 'react';
import {connect} from 'react-redux';

import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';


const BizAward = (props) => {
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >企业权益</Typography>
            <Divider  style={{borderWidth:2}} />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizAward);