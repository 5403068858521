import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');


export const getAsyncBizApplyList = async (obj) =>{
    try{
        let url = apiHost + '/admin/bizApply?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取入驻申请列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const updateBizApplyStatus = async (params) =>  {
    try {
        let url = apiHost + `/admin/bizApply/${params.id}/status`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("处理企业申请失败") 
            return {}
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
    }
}