import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { getAsyncBizApplyList } from '../../store/actions/main/BizApplyAction';
import { getAsyncUserList } from '../../store/actions/main/UserListAction';
import { getAsyncBaseProvidence,getAsyncBaseCity } from '../../store/actions/layout/BaseAction';
import { getDateTime,getBizApplyStatus } from '../../utils/CommonUtil';
import { bizApplyStatusList } from '../../utils/SysData';
import BizApplyModal from '../component/BizApplyModal';

const BizApply = (props) => {
    const [bizApplyList,setBizApplyList] = useState([])
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [providenceList,setProvidenceList] = useState([])
    const [cityList,setCityList] = useState([])

    const [modalVisible,setModelVisible] = useState(false)
    const [bizApply,setBizApply] = useState({})
    const [userInfo,setUserInfo] = useState({})
    const [bizShortName,setBizShortName] = useState("")
    const [bizName,setBizName] = useState("")
    const [bizOrgId,setBizOrgId] = useState("")
    const [master,setMaster] = useState("")
    const [masterId,setMasterId] = useState("")
    const [status,setStatus] = useState("")
    const [providenceId,setProvidenceId] = useState(0)
    const [cityId,setCityId] = useState(0)
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const getBizApply = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize,bizName,bizOrgId,bizShortName,master,
            masterId,status,providenceId,cityId
            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncBizApplyList(queryObj);
        if(res && res.list){
            setBizApplyList([...res.list])
            setTotal(res.total)
        }
    }
    const getUserInfo = async(params)=>{
        const res = await getAsyncUserList(params);
        if(res && res.list){
            setUserInfo(res.list[0])
        }
    }

    const openModal =(item)=>{
        setBizApply(item);
        getUserInfo({id:item.userId})
        setModelVisible(true);
    }
    const reset = () => {
        setBizShortName("");
        setBizName("");
        setBizOrgId("");
        setMaster("");
        setMasterId("");
        setStatus();
        setProvidenceId();
        setCityId();
        setCityList([])
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const changePage = (i)=>{
        setPageNumber(i)
        getBizApply(i)
    }
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }
    useEffect(()=>{
        getBizApply(pageNumber);
        getProvidence();
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >入驻申请</Typography>
            <Divider  style={{borderWidth:2}} />
            {/* <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业全称"
                                value={bizName}
                                onChange={(e)=>setBizName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="组织机构代码"
                                value={bizOrgId}
                                onChange={(e)=>setBizOrgId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="负责人"
                                value={master}
                                onChange={(e)=>setMaster(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="负责人身份证"
                                value={masterId}
                                onChange={(e)=>setMasterId(e.target.value)}
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >申请状态</InputLabel>
                                <Select label="申请状态"
                                    value={status}
                                    onChange={(event) => {
                                        setStatus(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {bizApplyStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在省份</InputLabel>
                                <Select label="所在省份"
                                    value={providenceId}
                                    onChange={(event, value) => {
                                        setProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >所在城市</InputLabel>
                                <Select label="所在城市"
                                    value={cityId}
                                    onChange={(event, value) => {
                                        setCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="申请开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="申请截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);getBizApply(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label"  >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">公司简称</TableCell>
                                <TableCell  align="center">组织机构代码</TableCell>
                                <TableCell  align="center">所在地区</TableCell>
                                <TableCell  align="center">成立日期</TableCell>
                                <TableCell  align="center">负责人</TableCell>
                                <TableCell  align="center">负责人身份证</TableCell>
                                <TableCell  align="center">申请状态</TableCell>
                                <TableCell  align="center">申请时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   bizApplyList && bizApplyList.length>0 && bizApplyList.map((row,index) => (
                                <TableRow key={"biz_apply_"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.bizShortName}</TableCell>
                                    <TableCell align="center" >{row.bizOrgId}</TableCell>
                                    <TableCell align="center" >{row.providenceName}-{row.cityName}-{row.areaName}</TableCell>
                                    <TableCell align="center" >{row.publishOn}</TableCell>
                                    <TableCell align="center" >{row.master}</TableCell>
                                    <TableCell align="center" >{row.masterId}</TableCell>
                                    <TableCell align="center" >{getBizApplyStatus(row.status)}</TableCell>
                                    <TableCell align="center" >{getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center" >
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={()=>{openModal(row)}}>
                                            <Icon path={md["mdiReceiptTextCheckOutline"]} size={1}/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            { bizApplyList.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {bizApplyList.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <BizApplyModal isOpen={modalVisible} entity={bizApply} userInfo={userInfo} id={bizApply.id} onComplete={()=>changePage(pageNumber)} onClose={()=>setModelVisible(false)}/>
             */}
        </Box>
    )
}


export default BizApply;