import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {Alert,AlertTitle,Autocomplete, Box, Button,Card,CardContent,Divider,FormControl,FormHelperText,Grid,IconButton,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,Select,MenuItem,
    InputLabel,Pagination,Paper,Stack, TextField,Typography} from '@mui/material';
import * as echarts from 'echarts';

import { getAsyncUserRegDayStat } from '../../store/actions/main/UserStatAction';
import { getAsyncBizDayStat } from '../../store/actions/main/BizStatAction';

const Home = (props) => {
    const [userRegDayXData , setUserRegDayXData] = useState([])
    const [userRegDayYData , setUserRegDayYData] = useState([])
    const getUserRegDayStat = async() => {
        const res = await getAsyncUserRegDayStat({total:7})
        let xData = [];
        let yData = [];
        for(let i =  res.length ;i>0;i--) {
            xData.push(res[i-1].id);
            yData.push(res[i-1].user_count);
        }
        const userDayStatBarChart = echarts.init(document.getElementById('user_day_stat_div'));
        userDayStatBarChart.setOption({                
            tooltip: {},
            xAxis: {
                data: xData
            },
            yAxis: {},
            series: [
                {
                name: '注册',
                type: 'bar',
                data: yData
                }
            ]
        });
    }
    const getBizInfoDayStat = async() => {
        const res = await getAsyncBizDayStat({total:7})
        let xData = [];
        let yData = [];
        for(let i =  res.length ;i>0;i--) {
            xData.push(res[i-1].id);
            yData.push(res[i-1].biz_count);
        }
        const userDayStatBarChart = echarts.init(document.getElementById('biz_day_stat_div'));
        userDayStatBarChart.setOption({                
            tooltip: {},
            xAxis: {
                data: xData
            },
            yAxis: {},
            series: [
                {
                name: '入驻',
                type: 'bar',
                data: yData
                }
            ]
        });
    }
    useEffect(()=>{
        getUserRegDayStat()
        getBizInfoDayStat()
    },[])
    return (
        <Box style={{paddingTop:10}}>       
            <Alert  severity="success">
                <AlertTitle>欢迎登录业聘直播招聘运营管理平台</AlertTitle>
                此平台为平台运营管理人员专用,可完成运营的日常任务以及查看平台的运营状况！
            </Alert>
            <Grid container spacing={2} style={{paddingTop:10}}>
                <Grid item xs={6}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}} style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>企业认证待审批</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}} style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>企业直播待审批</Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingTop:10}}>
                <Grid item xs={4}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}} style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>求职者投诉岗位</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}}  style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>企业投诉求职者</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}}  style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4" >0</Typography>
                            <Typography>求职者举报直播间</Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingTop:10}}>
                <Grid item xs={6}>
                    <Paper sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}} style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>今日新增用户</Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper  sx={{bgcolor:(theme) => (theme.palette.mode === 'dark' ? 'success.dark' : 'info.light')}} style={{textAlign: 'center',paddingTop:10,paddingBottom:10}} elevation={3}>
                        <Stack direction="column" Content="center"alignItems="center" spacing={2}>
                            <Typography variant="h4">0</Typography>
                            <Typography>今日通过审批企业</Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingTop:10}}>
                <Grid item xs={6}>
                    <Stack direction="column" alignItems="center">
                        <Typography   variant="h6" >最近7日新增用户统计</Typography>
                        <Box id="user_day_stat_div" style={{width:"100%",height:300}}></Box>
                    </Stack>  
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" alignItems="center">
                        <Typography   variant="h6" >最近7日企业通过审批统计</Typography>
                        <Box id="biz_day_stat_div" style={{width:"100%",height:300}}></Box>
                    </Stack>
                </Grid>
            </Grid>
            
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        initialValues: {userName:state.LoginReducer.userName,password:state.LoginReducer.password}
    }
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);