import React, { useState } from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";

import {Avatar,Box, Divider, Drawer, Grid, 
    List, ListItem,ListItemButton, ListItemIcon, 
    ListItemText,Stack,Toolbar,Typography} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import Viewer from 'react-viewer';

import {ALL_PAGE_LIST} from '../../utils/SysConst'
import {fileHost} from '../../config'
import NestedList from './NestedList';

const drawerWidth = 240;
const Navi = (props)=>{
  const {appReducer} = props;
  console.log(appReducer)
  const[open,setOpen] =useState(false)
  const [ avatarVisible, setAvatarVisible ] = useState(false);
    return (
        <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',borderRightWidth:5, },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <Stack direction="row" justifyContent="center" alignItems="center"spacing={2}>
            {appReducer.currentUser.avatar != '' && appReducer.currentUser.avatar != null ?
              <Avatar sx={{ bgcolor: deepOrange[500] }} onClick={()=>{setAvatarVisible(true)}} src={"http://"+fileHost+ appReducer.currentUser.avatar}/> :
              <Avatar sx={{ bgcolor: deepOrange[500] }}>{appReducer.currentUser.userName&&appReducer.currentUser.userName.slice(0,1)}</Avatar>
            }
            <Viewer
              zIndex={5000}
              noNavbar={true}
              noFooter={true}
              onMaskClick={()=>{setAvatarVisible(false)}}
              visible={avatarVisible}
              onClose={() => { setAvatarVisible(false); } }
              images={[{src: "http://"+fileHost+ appReducer.currentUser.avatar}]}
              />
            <Typography style={{paddingTop:20,paddingBottom:14}} variant="h6" gutterBottom>
              {appReducer.currentUser.userName}
            </Typography>
          </Stack>
          <Divider />
          <List>
            {appReducer.currentUserMenu.menuList.map((item, index) => {
              return (
                <div key={index+"_link"}>
                  {item.children.length === 0 &&
                    <NavLink exact="true" key={item.link+"link"} to={item.link} style={{textDecoration: 'none',color:"inherit"}}>
                      
                      <ListItem  key={item.link + index} disablePadding >
                        <ListItemButton key={"button"+item.link + index}>
                            <ListItemIcon key={"icon"+item.link + index}>
                            <Icon path={md[`${item.icon}`]} size={1}/>
                            </ListItemIcon>
                            <ListItemText key={"text"+item.link + index} primary={item.label}/>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                  }
                  {item.children.length > 0 &&
                     <NestedList  key={item.link+"nestlink"}  item={item}/>}       
                  
                </div>
              )
            })}
          </List>
        </Box>
      </Drawer>
    )
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.AppReducer
    }
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Navi);