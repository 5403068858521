import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';


import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import ProfileModal from '../component/ProfileModal';
import {genderOpts,eduGradeList,statusList,workStatusList} from '../../utils/SysData'
import { getAsyncUserProfileList ,exportUserProfile, updateAsyncUserStatus } from '../../store/actions/main/UserListAction';
import { getAsyncBaseProvidence,getAsyncBaseCity } from '../../store/actions/layout/BaseAction';
import {AppActionType} from '../../store/types';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';

const commonUtil = require('../../utils/CommonUtil')

const UserList = (props) => {
    const {openProfileById} = props
    const [profileModalVisible,setProfileModelVisible] = useState(false)
    const [profileModalTitle,setProfileTitle] = useState()
    const [profileModalUserId,setProfileUserId] = useState()
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)

    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [eduLevel,setEduLevel] = useState("")
    const [current,setCurrent] = useState("")
    const [userStatus,setUserStatus] = useState("")
    const [gender,setGender] = useState("")
    const [currentProvidenceId,setCurrentProvidenceId] = useState(0)
    const [currentCityId,setCurrentCityId] = useState(0)
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()
    const [providenceList,setProvidenceList] = useState([])
    const [cityList,setCityList] = useState([])
    
    const [profileList ,setProfileList] = useState([])
    const [total ,setTotal] = useState(0)

    const queryProfile = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize, name,phone,current,userStatus,
            currentProvidenceId,currentCityId,gender,eduLevel,
            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncUserProfileList(queryObj);
        if(res && res.list){
            console.log(res)
            setProfileList([...res.list])
            setTotal(res.total)
        }
    }

    
    const changePage = (i)=>{
        setPageNumber(i)
        queryProfile(i)
    }

    
    const getProvidence = async() => {
        const res = await getAsyncBaseProvidence({})
        if(res && res.list){
            let tmpArray =[]
            for(let i = 0; i<res.list.length;i++){
                tmpArray.push({label:res.list[i].name,value:res.list[i].id})
            }
            setProvidenceList([...tmpArray])
        }
    }
    const getCity = async(obj) => {
        if(obj.pid && obj.pid>0){
            const res = await getAsyncBaseCity(obj)
            if(res && res.list){
                let tmpArray =[]
                for(let i = 0; i<res.list.length;i++){
                    tmpArray.push({label:res.list[i].name,value:res.list[i].id})
                }
                setCityList([...tmpArray])
            }
        }
    }
    const reset = () => {
        setName("");
        setPhone("");
        setCurrent("");
        setUserStatus("");
        setGender("");
        setEduLevel("");
        setCurrentProvidenceId();
        setCurrentCityId();
        setCityList([])
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const exportUserProfileCsv = () => {
        const queryObj ={
            name,phone,current,userStatus,
            currentProvidenceId,currentCityId,gender,eduLevel,
            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        exportUserProfile(queryObj);
    }

    const updateUserStatus = async(index)=>{
       const obj = profileList[index];
       if(profileList[index].userStatus  ==1){
        obj.status = 0
       }else{
        obj.status = 1
       }
       const res = await updateAsyncUserStatus(obj)
        changePage(pageNumber)
        if(res){
            SuccessAlert("操作成功")
            queryProfile(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }

    const showUserProfile = (title,userId) => {
        setProfileTitle(title)
        setProfileUserId(userId)
        setProfileModelVisible(true)
    }
    useEffect(()=>{
        queryProfile(pageNumber)
        getProvidence()
    },[])
    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >简历库</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="姓名"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="电话"
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >性别</InputLabel>
                                <Select label="性别"
                                    value={gender}
                                    onChange={(event) => {
                                        setGender(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {genderOpts.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >学历</InputLabel>
                                <Select label="学历"
                                    value={eduLevel}
                                    onChange={(event) => {
                                        setEduLevel(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {eduGradeList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >求职状态</InputLabel>
                                <Select label="求职状态"
                                    value={current}
                                    onChange={(event) => {
                                        setCurrent(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {workStatusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >用户状态</InputLabel>
                                <Select label="用户状态"
                                    value={userStatus}
                                    onChange={(event) => {
                                        setUserStatus(event.target.value);
                                    }}>
                                    <MenuItem value="">请选择</MenuItem>
                                    {statusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >居住省份</InputLabel>
                                <Select label="居住省份"
                                    value={currentProvidenceId}
                                    onChange={(event, value) => {
                                        setCurrentProvidenceId(event.target.value);
                                        getCity({pid:event.target.value})
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {providenceList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >居住城市</InputLabel>
                                <Select label="居住城市"
                                    value={currentCityId}
                                    onChange={(event, value) => {
                                        setCurrentCityId(event.target.value);
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {cityList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="注册开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="注册截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);queryProfile(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label" onClick={exportUserProfileCsv} >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">姓名</TableCell>
                                <TableCell  align="center">性别</TableCell>
                                <TableCell  align="center">电话</TableCell>
                                <TableCell  align="center">年龄</TableCell>
                                <TableCell  align="center">学历</TableCell>
                                <TableCell  align="center">居住地</TableCell>
                                <TableCell  align="center">求职状态</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   profileList && profileList.length>0 && profileList.map((row,index) => (
                                <TableRow key={"user_profile_"+row.id}>
                                    <TableCell align="center" >{row.userId}</TableCell>
                                    <TableCell align="center" >{row.name}{row.userStatus}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(genderOpts, row.gender)}</TableCell>
                                    <TableCell align="center" >{row.phone}</TableCell>
                                    <TableCell align="center" >{commonUtil.getAgeByBirth(row.birth)}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(eduGradeList, row.eduLevel)}</TableCell>
                                    <TableCell align="center" >{row.currentProvidenceName}-{row.currentCityName}</TableCell>
                                    <TableCell align="center" >{commonUtil.getJsonValue(workStatusList, row.current)}</TableCell>
                                    <TableCell align="center" >
                                        <IconButton  color="primary" aria-label="edit" component="label"  onClick={()=>openProfileById(row.userId)}>
                                            <Icon path={md["mdiCardAccountDetailsOutline"]} size={1}/>
                                        </IconButton>
                                        <Switch checked={row.userStatus == 1} onChange={()=>{updateUserStatus(index)}}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            { profileList.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {profileList.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            {/* <ProfileModal open={profileModalVisible} title={profileModalTitle} userId={profileModalUserId} onClose={()=>setProfileModelVisible(false)}/> */}
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    openProfileById :(userId)=>{
        dispatch({type: AppActionType.setProfileUserId, payload: userId});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);