/* eslint-disable eqeqeq */
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');



export const getAsyncNotifySysList = async (obj) =>{
    try{
        let url = apiHost + '/admin/notifySys?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const createNotifySysAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/notifySys';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateNotifySysAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifySys/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const deleteNotifySysAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifySys/${params.id}`;
        let res = await trackPromise(httpUtil.httpDelete(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const getAsyncNotifyUserList = async (obj) =>{
    try{
        let url = apiHost + '/admin/notifyUser?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const createNotifyUserAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/notifyUser';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateNotifyUserAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifyUser/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const deleteNotifyUserAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifyUser/${params.id}`;
        let res = await trackPromise(httpUtil.httpDelete(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const getAsyncNotifyBizList = async (obj) =>{
    try{
        let url = apiHost + '/admin/notifyBiz?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const createNotifyBizAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/notifyBiz';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateNotifyBizAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifyBiz/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}


export const deleteNotifyBizAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/notifyBiz/${params.id}`;
        let res = await trackPromise(httpUtil.httpDelete(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}