import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import { Box,Button, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import {  getProdAsync,updateProdAsync } from '../../store/actions/main/ProdListAction';
import SimpleModal from '../layout/SimpleModal';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';

const commonUtil = require('../../utils/CommonUtil')

const ProdList = (props) => {
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)

    const [validation,setValidation] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const [modelObj,setModelObj] = useState({})
    const [modelList ,setModelList] = useState([])
    const [total ,setTotal] = useState(0)

    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize,            
        }
        
        const res = await getProdAsync(queryObj);
        if(res && res.list){
            setModelList([...res.list])
            setTotal(res.total)
        }
    }
    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const validate = ()=>{
        const validateObj ={};
        
        if (!modelObj.skuName || modelObj.skuName.length==0) {
            validateObj.skuName ='请输入商品名称';
        }        
        if (!modelObj.unitCount) {
            validateObj.unitCount ='请输入数量';
        }
        if (!modelObj.originPrice) {
            validateObj.originPrice ='请输入原价';
        }
        if (!modelObj.actualPrice) {
            validateObj.actualPrice ='请输入现价';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        
        setModalOpen(true)
        setModelObj(data)
    }
    const save = async()=>{
        const errorCount = validate();
        
        if(errorCount===0){
            const res = await updateProdAsync(modelObj)
            if(res){
                SuccessAlert("操作成功")
                query(pageNumber)   
            }else {
                ErrorAlert("操作失败")
            }
            setModalOpen(false)
        }
    }
    useEffect(()=>{
        query(pageNumber)
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >商品管理</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size='small' aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">商品名称</TableCell>
                                <TableCell  align="center">原价(元)</TableCell>
                                <TableCell  align="center">售价(元)</TableCell>
                                <TableCell  align="center">数量</TableCell>
                                <TableCell  align="center">下单时间</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   modelList && modelList.length>0 && modelList.map((row,index) => (
                                <TableRow key={"order_"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.skuName}</TableCell>
                                    <TableCell align="center" >{row.originPrice}</TableCell>
                                    <TableCell align="center" >{row.actualPrice}</TableCell>
                                    <TableCell align="center" >{row.unitCount}</TableCell>
                                    <TableCell align="center" >{commonUtil.getDateTime(row.CreatedAt)}</TableCell>   
                                    <TableCell align="center" >
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>
                                        
                                </TableRow>
                            ))}
                            { modelList.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelList.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
            <SimpleModal
                maxWidth={'sm'}
                title={'修改商品信息'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    <Grid item sm={12}><Typography color="primary">ID：{modelObj.id}</Typography></Grid>
                    <Grid item xs={6}>
                        <TextField label="商品名称" fullWidth margin="dense" variant="outlined" value={modelObj.skuName}
                                   onChange={(e) => {
                                    setModelObj({...modelObj,skuName:e.target.value});
                                   }}
                                   error={validation.skuName&&validation.skuName!=''}
                                   helperText={validation.skuName}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <TextField label="数量" type='number' fullWidth margin="dense" variant="outlined" value={modelObj.unitCount}
                                   onChange={(e) => {
                                    setModelObj({...modelObj,unitCount:Number.parseInt(e.target.value)});
                                   }}
                                   error={validation.unitCount&&validation.unitCount!=''}
                                   helperText={validation.unitCount}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="原价" type='number' fullWidth margin="dense" variant="outlined" value={modelObj.originPrice}
                                   onChange={(e) => {
                                    setModelObj({...modelObj,originPrice:Number.parseFloat(e.target.value)});
                                   }}
                                   error={validation.originPrice&&validation.originPrice!=''}
                                   helperText={validation.originPrice}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="售价" type='number' fullWidth margin="dense" variant="outlined" value={modelObj.actualPrice}
                                   onChange={(e) => {
                                    setModelObj({...modelObj,actualPrice:Number.parseFloat(e.target.value)});
                                   }}
                                   error={validation.actualPrice&&validation.actualPrice!=''}
                                   helperText={validation.actualPrice}
                        />
                    </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}


export default ProdList;