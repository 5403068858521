/* eslint-disable eqeqeq */
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');



export const getAsyncBizDayStat = async (obj) =>{
    try{
        const params = {}
        let url = apiHost + '/admin/bizInfoDayStat?';
        params.total = obj.total;
        let conditions = httpUtil.objToUrl(params);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpGet(url));
        if (res.success === true) {           
            return res.data.list;
        } else if (res.success === false) {
            sysAlert.WarningAlert("获取管理员列表信息失败");
            return []
        }
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return [] ;
    }
}