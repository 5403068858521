import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import {Avatar,Box,Button,Dialog,Divider,Grid, IconButton ,Paper,Stack,TextField, Tooltip, Typography} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs'

import { getAsyncUserProfileList,getAsyncUserProfileOpt,getAsyncUserProfileEdu,
    getAsyncUserProfileProject,getAsyncUserProfileWork } from '../../store/actions/main/UserListAction';
import {genderOpts,eduGradeList,posTypeList,workStatusList} from '../../utils/SysData'
import { AppActionType } from '../../store/types';
import { fileHost } from '../../config';
import { getAsyncBizApplyList, updateBizApplyStatus } from '../../store/actions/main/BizApplyAction';
import { getBizApplyStatus } from '../../utils/CommonUtil';

const commonUtil = require('../../utils/CommonUtil')

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: "#FFF",
        minWidth: 400
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, ...other} = props;
    return (
        <MuiDialogTitle disabletypography="true" className={classes.root} {...other}>
            <Typography style={{fontSize:"1.5rem"}}>{children}</Typography>
        </MuiDialogTitle>
    );
});


const DialogContent = withStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(2),
        }
    }
})(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    }
}))(MuiDialogActions);

const BizApplyModal = (props) => {
    const {id,entity,isOpen,onClose,userInfo ,onComplete} = props
    const [bizApply,setBizApply] = useState({})
    const [reply,setReply] = useState("")
    useEffect(()=>{
        getBizApply(id)
    },[])
    useEffect(()=>{
        if(isOpen){
            setReply("")
        }
    },[isOpen])
    const getBizApply = async (id)=>{
        const res = await getAsyncBizApplyList({id});
        if(res && res.list){            
            setBizApply(res.list[0])
        }
    }

    const openImageView = (domId) => {
        const viewer = new Viewer(document.getElementById(domId), {
            modal: true,
            viewed() {
              viewer.zoomTo(1);
            },
        });
    }

    const updateApplyStatus = async(status)=>{
        const params = {
            status,reply,id:entity.id
        }
        const res = await updateBizApplyStatus(params)
        onComplete("refresh")
        onClose()

    }
    return (
        <Dialog aria-labelledby="customized-dialog-title" open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle id="customized-dialog-title">
                {entity.bizShortName} 
            </DialogTitle>
            <DialogContent dividers>
                <Paper sx={{padding:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align="center">申请信息</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{padding:1}}>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                            <Grid item>企业: {entity.bizShortName}</Grid>
                            <Grid item>组织结构代码:{entity.bizOrgId}</Grid>
                        </Grid>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                            <Grid item>全称: {entity.bizName}</Grid>
                            <Grid item>成立日期: {entity.publishOn}</Grid>
                        </Grid>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                            <Grid item>城市: {entity.providenceName}-{entity.cityName}-{entity.areaName}</Grid>
                            <Grid item>地址: {entity.address}</Grid>
                        </Grid>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                            <Grid item>负责人: {entity.master}</Grid>
                        </Grid>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}> 
                            <Grid item>负责人身份证: {entity.masterId}</Grid>
                        </Grid>
                        <Grid mx={{margin:1}} container alignItems="center" justifyContent={"space-between"}>
                            <Grid item>申请时间: {commonUtil.formatDateTime(entity.CreatedAt)}</Grid>
                            {entity.status != 1 && <Grid item>处理时间: {commonUtil.formatDateTime(entity.UpdatedAt)}</Grid>}
                        </Grid>
                    </Box>
                    <Box >
                        <Grid  spacing={2} container alignItems="center" justifyContent={"center"}>
                            <Grid xs={6} item>
                                <Stack>
                                    <p>营业执照:</p>
                                    <img id="licImage" onClick={()=>{openImageView("licImage")}} style={{objectFit: "cover", maxHeight: 200}} src={"http://"+fileHost+ entity.licUrl} ></img>
                                </Stack>
                            </Grid>
                            <Grid xs={6} item>
                                <Stack>
                                    <p>授权书:</p>
                                    <img id="introImage" onClick={()=>{openImageView("introImage")}} style={{objectFit: "cover", maxHeight: 200}} src={"http://"+fileHost+ entity.introUrl} ></img>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid  spacing={2} container alignItems="center" justifyContent={"center"}>
                            <Grid xs={6} item>
                                <Stack>
                                    <p>负责人身份证正面:</p>
                                    <img id="masterImage" onClick={()=>{openImageView("masterImage")}} style={{objectFit: "cover", maxHeight: 200}} src={"http://"+fileHost+ entity.masterIdUrl} ></img>
                                </Stack>
                            </Grid>
                            <Grid xs={6} item>
                                <Stack>
                                    <p>负责人身份证反面:</p>
                                    <img id="masterReImage" onClick={()=>{openImageView("masterReImage")}} style={{objectFit: "cover", maxHeight: 200}} src={"http://"+fileHost+ entity.masterIdReUrl} ></img>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>  
                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align="center">申请用户</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={10}>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>姓名: {userInfo.name}</Grid>
                                <Grid item xs={5}>性别: {commonUtil.getJsonValue(genderOpts, userInfo.gender)}</Grid>
                            </Grid>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}>
                                <Grid item xs={7}>电话: {userInfo.phone}</Grid>
                                <Grid item xs={5}>生日: {commonUtil.getYearMonth(userInfo.birth)}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sx={{display:"flex"}}justifyContent={"flex-end"}>
                        {userInfo.avatar && userInfo.avatar !='' && 
                            <Avatar src={"http://"+fileHost+ userInfo.avatar} sx={{ width: 64, height: 64}}   />}
                        </Grid>
                    </Grid>
                </Paper>  
                <Paper sx={{padding:2,marginTop:2}} elevation={3}>
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="div" align="center">处理申请</Typography>
                        </Grid>
                    </Grid>
                    { entity.status != 1 &&
                    <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Grid container sx={{marginBottom:1}} alignItems="center" justifyContent={"space-between"}></Grid>
                            <Grid item xs={12}>{getBizApplyStatus(entity.status)}:{entity.reply}</Grid>
                            <Grid item xs={12} pt={1}>处理时间:{new Date(entity.UpdatedAt).toLocaleString()}</Grid>
                        </Grid>
                        
                    </Grid>}
                    {entity.status == 1 && <Grid container alignItems="center" justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField id="input-with-sx" value={reply} onChange={(e)=>setReply(e.target.value)} label="审批意见" style={{width:"80%"}} variant="standard" />
                                <Tooltip title="通过">
                                    <IconButton  onClick={()=>{updateApplyStatus(3)}}>
                                        <Icon path={md["mdiCheckCircle"]} style={{paddingLeft:10,color:"green"}} size={1.5}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="驳回">
                                    <IconButton  onClick={()=>{updateApplyStatus(2)}}>
                                    <Icon path={md["mdiHandBackLeftOff"]} style={{paddingLeft:10,color:"orange"}} size={1.5} />
                                    </IconButton>
                                </Tooltip>
                               
                                    
                               
                                
                            </Box>
                        </Grid>
                    </Grid>}
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button  onClick={onClose}>关闭</Button>
            </DialogActions>
        </Dialog>
    )
}



export default BizApplyModal