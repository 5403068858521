import React, {useEffect,useState} from 'react';

import { Box,Button, Divider,FormControl,FormHelperText,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import { useConfirm } from "material-ui-confirm";
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import SimpleModal from '../layout/SimpleModal';
import { createCmsTypeAsync, getAsyncCmsTypeList, updateCmsTypeAsync, updateNotifyUser } from '../../store/actions/main/CmsAction';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
const commonUtil = require('../../utils/CommonUtil')
const {sysNotifyTypeList, statusList} = require('../../utils/SysData')

const CmsTypeList = (props) => {
    const confirm = useConfirm();

    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

   
    

    const [cmsTypeObj,setCmsTypeObj] = useState({})
    const [cmsTypeArray,setCmsTypeArray] = useState([])

    const [modalOpen, setModalOpen] = useState(false);
    const [newModal, setNewModal] = useState(true);
    const [validation,setValidation] = useState({});

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        console.log(data)
        setModalOpen(true)
        if(data == null){
            setNewModal(true)
            setCmsTypeObj({})
        }else{
            setNewModal(false)
            setCmsTypeObj(data)
        }
    }
    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize            
        }
        
        const res = await getAsyncCmsTypeList(queryObj);
        if(res && res.list){
            setCmsTypeArray([...res.list])
            setTotal(res.total)
        }
    }

    const validate = ()=>{
        const validateObj ={};
        
        if (!cmsTypeObj.name || cmsTypeObj.name.length==0) {
            validateObj.name ='请输入内容类型名字';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const save = ()=> {
        const errorCount = validate();
        if(errorCount===0){
            setModalOpen(false);
            newModal?createCmsType():updateCmsType()
        }
    }
    const reset = () => {
        
    }
    const createCmsType = async()=> {
        const res = await createCmsTypeAsync(cmsTypeObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)
        }else {
            ErrorAlert("操作失败")
        }
       
    }

    const updateCmsType = async()=>{        
        const res = await updateCmsTypeAsync(cmsTypeObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    const updateStatus = async(i) => {
        const statusObj = {
            id : cmsTypeArray[i].id,
            status: cmsTypeArray[i].status == 1? 0:1
        }
        const res = await updateCmsTypeAsync(statusObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    useEffect(()=>{
        query(pageNumber)
    },[])
    return (
        
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >系统消息</Typography>
            <Divider  style={{borderWidth:2}} />

            <Grid container  spacing={1} style={{paddingTop:10}}> 
                <Grid item md={10}></Grid>               
                <Grid item xs={2}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                                <IconButton color="primary" aria-label="reset" component="label" onClick={reset} >
                                    <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                                </IconButton>
                        </Tooltip>
                        <IconButton color="primary" aria-label="add" onClick={() => {openModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                        <IconButton color="primary" aria-label="query" component="label" onClick={() => {query()}} >
                            <Icon path={md["mdiMagnify"]} size={1.5}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">类型</TableCell>
                                <TableCell  align="center">创建日期</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cmsTypeArray.length > 0 && cmsTypeArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                    </TableCell>                                    
                                </TableRow>))}
                                { cmsTypeArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {cmsTypeArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }

            <SimpleModal
                maxWidth={'sm'}
                title={ newModal ? '新增内容类型' : '修改内容类型'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    {!newModal && <Grid item sm={12}><Typography color="primary">ID：{cmsTypeObj.id}</Typography></Grid>}
                    
                    <Grid item xs={12}>
                        <TextField label="类型名称" fullWidth margin="dense" variant="outlined" value={cmsTypeObj.name}
                                   onChange={(e) => {
                                        setCmsTypeObj({...cmsTypeObj,name:e.target.value});
                                   }}
                                   error={validation.name&&validation.name!=''}
                                   helperText={validation.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="备注" fullWidth 
                            variant="outlined"  multiline
                            maxRows={4} value={cmsTypeObj.remark}
                            onChange={(e) => {
                                    setCmsTypeObj({...cmsTypeObj,remark:e.target.value});
                            }}
                            error={validation.remark&&validation.remark!=''}
                            helperText={validation.remark}
                        />
                    </Grid>
                </Grid>

            </SimpleModal>
        </Box>
    )
}


export default CmsTypeList;