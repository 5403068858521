import {handleActions} from 'redux-actions';
import {UserStatActionType} from '../../types';

const initialState = {
    totalDay : 15,
    userRegDayList :[]
}

export default handleActions({
    [UserStatActionType.setUserRegDayStat]: (state, action) => {
        return {
            ...state,
            userRegDayList: action.payload
        }
    },
}, initialState)