import {handleActions} from 'redux-actions';
import {BizListActionType} from '../../types';

const initialState = {
    queryParams : {
        pageNumber:1,
        pageSize:10,
        bizShortName:"",
        bizName:"",
        bizOrgId:"",
        scaleType:"",
        restType:"",
        bizType:"",
        providenceId:"",
        cityId:"",
        createdStart: null,
        createdEnd: null,

    }
}


export default handleActions({    
    [BizListActionType.setBizListParams]: (state, action) => {
        const {name, value} = action.payload;
        const paramsObj = {...state.queryParams, [name]: value};
        return {
            ...state,
            queryParams: paramsObj
        }
    },
    [BizListActionType.resetBizListParams]: (state, action) => {
        
        return {
            ...state,
            queryParams: initialState.queryParams
        }
    }
}, initialState)