import React, {useEffect,useRef,useState} from 'react';

import { Box,Button, Divider,FormControl,FormHelperText,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConfirm } from "material-ui-confirm";
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import SimpleModal from '../layout/SimpleModal';
import { fileHost } from '../../config';
import { createCmsContentAsync, getAsyncCmsContentDetail, getAsyncCmsContentList, getAsyncCmsTypeList, updateCmsContentAsync, updateNotifyUser } from '../../store/actions/main/CmsAction';
import { ErrorAlert, SuccessAlert } from '../../utils/SysAlert';
import { updateImageAsync, uploadBaseImage } from '../../store/actions/layout/AppAction';
const commonUtil = require('../../utils/CommonUtil')
const {sysNotifyTypeList, statusList} = require('../../utils/SysData')


const CmsContentList = (props) => {
    const confirm = useConfirm();
    const contentTpl = `
# 一级标题 
## 二级标题 
### 三级标题 
#### 四级标题 
##### 五级标题 
###### 六级标题 
  
正文
  
### 项目符号
1. Lorem ipsum dolor sit amet
2. Consectetur adipiscing elit
3. Integer molestie lorem at massa
  

## 表格
  
  | Option | Description |
  | ------ | ----------- |
  | data   | path to data files to supply the data that will be passed into templates. |
  | engine | engine to be used for processing templates. Handlebars is the default. |
  | ext    | extension to be used for dest files. |
  
## 图片
  
  ![](https://octodex.github.com/images/minion.png)

  ![](https://octodex.github.com/images/stormtroopocat.jpg )
  
  `;
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)
    const [total ,setTotal] = useState(0)

    const [typeId,setTypeId] = useState("")
    const [status,setStatus] = useState("")
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const [cmsTypeList,setCmsTypeList] = useState([])
    const [cmsContentObj,setCmsContentObj] = useState({})
    const [cmsContentArray,setCmsContentArray] = useState([])

    const [imageList ,setImageList ] = useState([])
    
    const [modalOpen, setModalOpen] = useState(false);
    const [newModal, setNewModal] = useState(true);
    const [validation,setValidation] = useState({});

    const fileInputRef = useRef();

    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = (data)=>{
        console.log(data)
        setModalOpen(true)
        if(data == null){
            setNewModal(true)
            setCmsContentObj({content:contentTpl})
        }else{
            setNewModal(false)
            getCmsDetail(data.id)
        }
    }
    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize,typeId,status            
        }
        
        const res = await getAsyncCmsContentList(queryObj);
        if(res && res.list){
            setCmsContentArray([...res.list])
            setTotal(res.total)
        }
    }
    const getCmsTypeList = async()=>{
        const res = await getAsyncCmsTypeList({});
        if(res && res.list){
            setCmsTypeList(res.list)
        }
    }

    const getCmsDetail = async(id) => {
        const res = await getAsyncCmsContentDetail({id});
        console.log(res)
        if(res && res.list){
            setCmsContentObj(res.list)
        }
    }

    const onSelectFile = async(e) => {
        if (e.target.files && e.target.files.length > 0) {
          const fd = new FormData()
          fd.append('file', e.target.files[0])
          const res = await updateImageAsync({fd})
          setImageList([...imageList,{url:res}])
        }else{
            ErrorAlert("文件选择错误")
        }
    }
    const validate = ()=>{
        const validateObj ={};
        if (!cmsContentObj.typeId ) {
            validateObj.typeId ='请选择内容类型';
        }
        if (!cmsContentObj.title || cmsContentObj.title.length==0) {
            validateObj.title ='请输入标题';
        }
        if (!cmsContentObj.content || cmsContentObj.content.length==0) {
            validateObj.content ='请输入内容';
        }
        setValidation(validateObj);
        return Object.keys(validateObj).length
    };

    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const save = ()=> {
        const errorCount = validate();
        if(errorCount===0){
            setModalOpen(false);
            newModal?createCmsContent():updateCmsContent()
        }
    }
    const reset = () => {
        setStatus();
        setTypeId();
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const createCmsContent = async()=> {
        const res = await createCmsContentAsync(cmsContentObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)
        }else {
            ErrorAlert("操作失败")
        }
       
    }

    const updateCmsContent = async()=>{        
        const res = await updateCmsContentAsync(cmsContentObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    const updateStatus = async(i) => {
        const statusObj = {
            id : cmsContentArray[i].id,
            status: cmsContentArray[i].status == 1? 0:1
        }
        const res = await updateCmsContentAsync(statusObj)
        if(res){
            SuccessAlert("操作成功")
            query(pageNumber)   
        }else {
            ErrorAlert("操作失败")
        }
    }
    
    useEffect(()=>{
        query(pageNumber)
        getCmsTypeList()
    },[])
    return (
        
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >系统消息</Typography>
            <Divider  style={{borderWidth:2}} />

            <Grid container  spacing={1} style={{paddingTop:10}}> 
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >内容类型</InputLabel>
                                <Select label="内容类型"
                                        value={typeId}
                                        onChange={(event, value) => {
                                            setTypeId(event.target.value);
                                        }}
                                >
                                    <MenuItem value="">请选择</MenuItem>
                                    {cmsTypeList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel >内容状态</InputLabel>
                                <Select label="内容状态"
                                    value={status}
                                    onChange={(event, value) => {
                                        setStatus(event.target.value);                                        
                                    }}>
                                    <MenuItem value={0} disabled>请选择</MenuItem>
                                    {statusList.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="创建截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>               
                <Grid item xs={2}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                                <IconButton color="primary" aria-label="reset" component="label" onClick={reset} >
                                    <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                                </IconButton>
                        </Tooltip>
                        <IconButton color="primary" aria-label="add" onClick={() => {openModal()}} >
                            <Icon path={md["mdiPlusCircle"]} size={1.5}/>
                        </IconButton>
                        <IconButton color="primary" aria-label="query" component="label" onClick={() => {query()}} >
                            <Icon path={md["mdiMagnify"]} size={1.5}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table  size={'small'} aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">类型</TableCell>
                                <TableCell  align="center">标题</TableCell>
                                <TableCell  align="center">创建日期</TableCell>
                                <TableCell  align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cmsContentArray.length > 0 && cmsContentArray.map((row,index) => (
                                <TableRow key={"notify_sys_item"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.title}</TableCell>
                                    <TableCell align="center">{commonUtil.getDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton  color="primary" aria-label="edit" component="label" onClick={() => {openModal(row)}} >
                                            <Icon path={md["mdiLeadPencil"]} size={1}/>
                                        </IconButton>
                                        <Switch checked={row.status == 1} onChange={()=>{updateStatus(index)}}/>
                                    </TableCell>                                    
                                </TableRow>))}
                                { cmsContentArray.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                                }
                                
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {cmsContentArray.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }

            <SimpleModal
                maxWidth={'xl'}
                title={ newModal ? '新增内容类型' : '修改内容类型'}
                open={modalOpen}
                onClose={closeModal}
                showFooter={true}
                footer={
                    <>
                        <Button variant="contained" color="primary" onClick={()=>{save()}}>确定</Button>
                        <Button  onClick={closeModal}>关闭</Button>
                    </>
                }
            >
                <Grid container spacing={1}>
                    {!newModal && <Grid item sm={12}><Typography color="primary">ID：{cmsContentObj.id}</Typography></Grid>}
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                                <InputLabel>内容类型</InputLabel>
                                <Select label="内容类型"
                                    value={cmsContentObj.typeId}
                                    onChange={(event, value) => {
                                        setCmsContentObj({...cmsContentObj,typeId:event.target.value});
                                    }}
                                    error={validation.typeId&&validation.typeId!=''}
                                >
                                    {cmsTypeList.map((item, index) => (
                                        <MenuItem key={"cms_content_"+item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                                {(validation.typeId&&validation.typeId!='') && <FormHelperText style={{color: 'red'}}>{validation.typeId}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="标题" fullWidth margin="dense" variant="outlined" value={cmsContentObj.title}
                                    onChange={(e) => {
                                            setCmsContentObj({...cmsContentObj,title:e.target.value});
                                    }}
                                    error={validation.title&&validation.title!=''}
                                    helperText={validation.title}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{marginTop:1}}>
                            <TextField label="内容" fullWidth 
                                variant="outlined"  multiline
                                maxRows={10} value={cmsContentObj.content}
                                onChange={(e) => {
                                        setCmsContentObj({...cmsContentObj,content:e.target.value});
                                }}
                                error={validation.content&&validation.content!=''}
                                helperText={validation.content}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12}>                           
                            <Box sx={{borderWidth:2,borderStyle: "groove",borderColor: "#f2f2f2",
                            borderRadius: 1,width:"100%",marginTop:"8px",minHeight:"200px"}}>
                                <Stack direction={"column"} sx={{padding:2}}>
                                    <Button component="label" variant="contained" 
                                        onClick={()=>{fileInputRef.current.click()}}
                                        startIcon={<Icon path={md["mdiCloudUploadOutline"]} size={1.5}/>}>
                                        图片上传
                                    </Button>
                                    <input type="file" style={{display:"none"}} ref={fileInputRef} accept="image/*" onChange={onSelectFile} />
                                    <Stack direction={"row"} sx={{marginTop:2}}>
                                        
                                        {imageList.map((item)=>{
                                            return (<div key={"_cms_img_"+item.url}>
                                                <img style={{objectFit: "cover", maxHeight: 120}} src={"http://"+fileHost+item.url} ></img>
                                                <div style={{marginTop:"-2rem",paddingLeft:2}}>
                                                <CopyToClipboard onCopy={()=>{SuccessAlert("已复制到剪贴板")}} text={"http://"+fileHost+item.url}>
                                                    <Icon path={md["mdiLink"]} size={1} />
                                                </CopyToClipboard>
                                                </div>
                                            </div>)
                                        })}
                                        
                                        
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{borderWidth:2,borderStyle: "groove",borderColor: "#f2f2f2",
                                borderRadius: 1,width:"100%",marginTop:"8px",minHeight:"200px"}}>
                                     <div style={{marginTop:2,maxHeight:"300px",overflow:"scroll"}}>
                                     <Markdown remarkPlugins={[remarkGfm]} >{cmsContentObj.content}</Markdown>
                                     </div>
                            </Box>
                        </Grid>
                    </Grid>
                    
                </Grid>

            </SimpleModal>
        </Box>
    )
}


export default CmsContentList;