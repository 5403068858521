import {handleActions} from 'redux-actions';
import {BizStatActionType} from '../../types';

const initialState = {
    totalDay : 30,
    bizApplyDayList :[],
    bizDayList :[]
}

export default handleActions({
    [BizStatActionType.getBizApplyDayStat]: (state, action) => {
        return {
            ...state,
            bizApplyDayList: action.payload
        }
    },
    [BizStatActionType.getBizDayStat]: (state, action) => {
        return {
            ...state,
            bizDayList: action.payload
        }
    },
}, initialState)