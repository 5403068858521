import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Box, Divider,Stack,Typography} from '@mui/material';
import * as echarts from 'echarts';
import { getUserRegDayStat } from '../../store/actions/main/UserStatAction';

const BizStat = (props) => {
    return (
        <Box style={{paddingTop:10}}>            
        <Typography  gutterBottom variant="h6" >企业统计</Typography>
        <Divider  style={{borderWidth:2,marginBottom:10}} /> 
        <Stack direction="column" alignItems="center">
            <Typography  gutterBottom variant="h6" >最近30日企业认证申请统计</Typography>
            <Box id="user_day_stat_div" style={{width:"100%",height:300}}></Box>
        </Stack>  
        <Stack direction="column" alignItems="center">
            <Typography  gutterBottom variant="h6" >最近30日新增企业统计</Typography>
            <Box id="user_day_stat_div" style={{width:"100%",height:300}}></Box>
        </Stack> 
    </Box>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(BizStat);