import React, {useEffect, useState,useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Button, Grid, TextField} from '@mui/material';
import ReactCrop, {centerCrop,makeAspectCrop,Crop,PixelCrop  } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {uploadBaseImage} from '../../store/actions/layout/AppAction'
import SimpleModal from './SimpleModal'


const EditAvatarModal = (props) => {
    const {openFlag, closeEditModal , uploadBaseImage } = props;
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [aspect, setAspect] = useState(1)

    const [imgSrc, setImgSrc] = useState('')
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
  

    const dispatch = useDispatch();
    const fileInputRef = useRef();
    const imgRef = useRef();


    useEffect(() => {
       setImgSrc('')
       setCrop({})
       setCompletedCrop({})
    }, [openFlag]);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setCrop(undefined) // Makes crop preview update between images.
          const reader = new FileReader()
          reader.addEventListener('load', () =>
            setImgSrc(reader.result?.toString() || ''),
          )
          reader.readAsDataURL(e.target.files[0])
        }
    }
    const clickFileInput = () => {
        fileInputRef.current.click()
    }
    const centerAspectCrop = (mediaWidth,mediaHeight,aspect) =>{
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),mediaWidth, mediaHeight
        )
    }
    const onImageLoad = (e) =>{
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
    }
    const getImagePortion = (imgObj, newWidth, newHeight, startX, startY) =>{
        const scaleX = imgObj.naturalWidth / imgObj.width
        const scaleY = imgObj.naturalHeight / imgObj.height
        
        const bufferCanvas = document.createElement('canvas');
        const bufferContext = bufferCanvas.getContext('2d');
        bufferCanvas.width = newWidth;
        bufferCanvas.height = newHeight;
        bufferContext.drawImage(imgObj, scaleX * startX, scaleY * startY,scaleX*newWidth ,scaleY*newHeight,0,0, newWidth,newHeight);
        
        /* bufferCanvas.toBlob(blob => {
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current)
            }
            blobUrlRef.current = URL.createObjectURL(blob)
            const downloadHref = document.createElement('a');
            downloadHref.href = URL.createObjectURL(blob)
            downloadHref.download = 'origin.png'
            downloadHref.click()
            downloadHref.remove()
            URL.revokeObjectURL(downloadHref.href)
        }); */

        return bufferCanvas.toDataURL()
    }
    const submitModal = async () => {
        const res = getImagePortion(imgRef.current,completedCrop.width,completedCrop.height,completedCrop.x,completedCrop.y)
        const resBase64 = res.replace(/^data:image\/\w+;base64,/,"")
        uploadBaseImage(resBase64);
        closeEditModal();
    }
    return (
        <SimpleModal
            maxWidth={'sm'}
            title="编辑头像"
            open={openFlag}
            onClose={closeEditModal}
            showFooter={true}
            footer={
                <>
                    {!!imgSrc && <Button variant="contained" color="primary" visible={false} onClick={submitModal}>确定</Button>}
                    <Button variant="outlined" autoFocus onClick={closeEditModal}>关闭</Button>
                </>
            }>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Button fullWidth={true} variant="contained" color="primary" onClick={clickFileInput}>选择图片</Button>
                    <input type="file" style={{display:"none"}} ref={fileInputRef} accept="image/*" onChange={onSelectFile} />
                    {!!imgSrc && (
                        <ReactCrop
                        style={{marginTop:12}}
                        crop={crop}
                        onChange={(e, percentCrop) => {setCrop(percentCrop)}}
                        onComplete={(c) => {setCompletedCrop(c)}}
                        aspect={aspect}
                        >
                        <img style={{width:"100%",transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            onLoad={onImageLoad}
                        />
                        </ReactCrop>
                    )}
                </Grid>
            </SimpleModal>
    )

}


const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    uploadBaseImage : (baseCode) => { dispatch(uploadBaseImage(baseCode)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAvatarModal)