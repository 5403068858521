import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';

import { Box, Divider,FormControl,Grid,IconButton,InputLabel,MenuItem,Paper,
    Select,Stack,Switch,Pagination,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    TextField,Tooltip,Typography} from '@mui/material';
import Icon from '@mdi/react'
import * as md from '@mdi/js'
import { DatePicker, DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';

import {genderOpts,eduGradeList,statusList,workStatusList, orderStatusList} from '../../utils/SysData'
import { getAsyncBizOrder  } from '../../store/actions/main/OrderListAction';

const commonUtil = require('../../utils/CommonUtil')

const OrderList = (props) => {
    const [pageNumber,setPageNumber] = useState(1)
    const [pageSize,setPageSize] = useState(10)

    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [bizShortName,setBizShortName] = useState("")
    const [bizName,setBizName] = useState("")
    const [bizOrgId,setBizOrgId] = useState("")
    const [status,setStatus] = useState("")
    const [paymentStatus,setPaymentStatus] = useState("")
    const [refundStatus,setRefundStatus] = useState("")
    const [createdStart,setCreatedStart] = useState()
    const [createdEnd,setCreatedEnd] = useState()

    const [modelList ,setModelList] = useState([])
    const [total ,setTotal] = useState(0)

    const query = async (pageNumber) => {
        const queryObj ={
            pageNumber,pageSize, name,phone,bizName,bizShortName,bizOrgId,
            status,paymentStatus,refundStatus,
            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        const res = await getAsyncBizOrder(queryObj);
        if(res && res.list){
            console.log(res)
            setModelList([...res.list])
            setTotal(res.total)
        }
    }
    const changePage = (i)=>{
        setPageNumber(i)
        query(i)
    }
    const reset = () => {
        setName("");
        setPhone("");
        setBizName("");
        setBizShortName("");
        setBizOrgId("");
        setStatus("");
        setCreatedStart(null)
        setCreatedEnd(null)
    }
    const exportCsv = () => {
        const queryObj ={
            name,phone,bizName,bizShortName,bizOrgId,
            status,paymentStatus,refundStatus,
            
        }
        if(createdStart != null && createdStart != ""){
            queryObj.createdStart = createdStart+ "T00:00:00Z"
        }
        if(createdEnd != null && createdEnd != ""){
            queryObj.createdEnd = createdEnd+ "T23:59:59Z"
        }
        console.log(queryObj,'export csv')
    }

    useEffect(()=>{
        query(pageNumber)
    },[])

    return (
        <Box style={{paddingTop:10}}>      
            <Typography gutterBottom variant="h6" >订单管理</Typography>
            <Divider  style={{borderWidth:2}} />
            <Grid container  spacing={1} style={{paddingTop:10}}>
                <Grid item md={10}>
                    <Grid container  spacing={1}>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="姓名"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="电话"
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                label="企业简称"
                                value={bizShortName}
                                onChange={(e)=>setBizShortName(e.target.value)}
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid container  spacing={1} style={{marginTop:10}}>
                        <Grid item xs>
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel >订单状态</InputLabel>
                                    <Select label="订单状态"
                                        value={paymentStatus}
                                        onChange={(event) => {
                                            setPaymentStatus(event.target.value);
                                        }}>
                                        <MenuItem value="">请选择</MenuItem>
                                        {orderStatusList.map((item) => (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        <Grid item xs>                            
                            <DatePicker label="下单开始日期" value={createdStart} onChange={e=>setCreatedStart(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                        <Grid item xs>                            
                            <DatePicker label="下单截止日期"  value={createdEnd}  onChange={e=>setCreatedEnd(e.format('YYYY-MM-DD'))} disableFuture sx={{width:"100%"}}  margin="dense" size='small' format="YYYY-MM-DD" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} style={{display:"flex",alignItems:"center"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-around" style={{marginTop:1}}>
                        <Tooltip title="重置">
                            <IconButton color="secondary" aria-label="reset" component="label" onClick={reset} >
                                <Icon path={md["mdiBackupRestore"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="搜索">
                            <IconButton color="primary" aria-label="query" component="label" onClick={()=>{setPageNumber(1);query(1);}} >
                                <Icon path={md["mdiMagnify"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="导出">
                            <IconButton  color="primary" aria-label="download" component="label" onClick={exportCsv} >
                                <Icon path={md["mdiArrowDownBoldCircle"]} size={1.5}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingLeft:15}}>
                <TableContainer component={Paper} style={{marginTop:40}}>
                    <Table   aria-label="a dense table">
                        <TableHead >
                            <TableRow style={{height:50}}>
                                <TableCell  align="center">ID</TableCell>
                                <TableCell  align="center">姓名</TableCell>
                                <TableCell  align="center">电话</TableCell>
                                <TableCell  align="center">企业简称</TableCell>
                                <TableCell  align="center">订单内容</TableCell>
                                <TableCell  align="center">订单价格(元)</TableCell>
                                <TableCell  align="center">订单状态</TableCell>
                                <TableCell  align="center">下单时间</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   modelList && modelList.length>0 && modelList.map((row,index) => (
                                <TableRow key={"order_"+row.id}>
                                    <TableCell align="center" >{row.id}</TableCell>
                                    <TableCell align="center" >{row.name}</TableCell>
                                    <TableCell align="center" >{row.phone}</TableCell>
                                    <TableCell align="center" >{row.bizShortName}</TableCell>
                                    <TableCell align="center" >{row.body}</TableCell>
                                    <TableCell align="center" >{row.actualAmount}</TableCell>                                    
                                    <TableCell align="center" >{commonUtil.getJsonValue(orderStatusList, row.paymentStatus)}</TableCell>
                                    <TableCell align="center" >{commonUtil.getDateTime(row.CreatedAt)}</TableCell>   
                                    
                                </TableRow>
                            ))}
                            { modelList.length === 0 &&
                                    <TableRow style={{height:60}}><TableCell align="center" colSpan="10">暂无数据</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {modelList.length>0 && 
                <Grid container justifyContent="space-between" alignItems="center" style={{paddingTop:10}}>
                    <Typography>{total} 条</Typography>
                <Pagination page={pageNumber} onChange={(e,pageNumber)=>{changePage(pageNumber)}} count={Math.ceil(total/pageSize)} color="primary" />
                </Grid>
            }
        </Box>
    )
}


export default OrderList;