import {combineReducers} from 'redux'
import {reducer as reduxFormReducer} from 'redux-form';
import AppReducer from './layout/AppReducer';
import CommonReducer from './layout/CommonReducer';
import LoginReducer from './login/LoginReducer';
import MainPanelReducer from './main/MainPanelReducer';
import SysAuthReducer from './main/SysAuthReducer';
import SysInfoReducer from './main/SysInfoReducer';
import SysUserReducer from "./main/SysUserReducer";
import AppSettingReducer from "./main/AppSettingReducer";
import BizListReducer from "./main/BizListReducer";
import BizStatReducer from "./main/BizStatReducer";
import UserStatReducer from "./main/UserStatReducer";

export default combineReducers({
    form: reduxFormReducer,
    AppReducer,
    CommonReducer,
    LoginReducer,
    MainPanelReducer,
    SysAuthReducer,
    SysInfoReducer,
    SysUserReducer,
    AppSettingReducer,
    BizListReducer,
    BizStatReducer,
    UserStatReducer,
});
