import {apiHost , fileHost} from '../../../config';
import {AppActionType} from '../../types';
import { trackPromise } from 'react-promise-tracker';

const httpUtil = require('../../../utils/HttpUtils');
const httpHeaders = require('../../../utils/HttpHeaders');
const localUtil = require('../../../utils/LocalUtils');
const commonUtil = require('../../../utils/CommonUtil');
const sysConst = require('../../../utils/SysConst');
const sysAlert = require('../../../utils/SysAlert');

export const getCurrentAdmin = () => async (dispatch) => {
    try {
        // admin用户 检索 URL
        const url = apiHost + '/admin/sysUser';
        const token = localUtil.getSessionItem(sysConst.AUTH_TOKEN);
        if(token ==null || token ===""){
            window.location.href = '/login';
        }
        httpHeaders.set(sysConst.AUTH_TOKEN,token)
        // 发送 get 请求
        //console.log(url)
        const res = await trackPromise(httpUtil.httpGet(url));
        if (res.success) {
            if (res.data) {
                dispatch({type: AppActionType.setCurrentUser, payload: res.data.adminInfo})
                dispatch({type: AppActionType.setCurrentUserMenu, payload: commonUtil.objToMap(res.data.adminType[0].menuList)});
            } else {
                window.location.href = '/login';
            }
        } else if (!res.success) {
            sysAlert.WarningAlert('获取权限失败');
            window.location.href = '/login';
        }
    } catch (err) {
        sysAlert.ErrorAlert('获取权限失败');
    }
};

export const updateAdminPassword =(params) => async () => {
    try {
        // admin用户 检索 URL
        const url = fileHost + '/admin/password';
        const res = await httpUtil.httpPut(url,params);
        return res
    } catch (err) {
        sysAlert.ErrorAlert('密码更新失败');
    }
}


export const uploadBaseImage = (baseImage) => async (dispatch) => {
    try {
        
        const fd = new FormData()
        fd.append('base_file', baseImage)
        // 状态
        let url = fileHost + '/api/admin/avatarBase'
        let res = await trackPromise(httpUtil.httpAsyncFormPost(url, fd));
        if (res.success) {
            url = apiHost + '/admin/avatar'
            const params = {avatar:res.data}
            let uploadRes = await trackPromise(httpUtil.httpPut(url, params));
            if(uploadRes.success){
                sysAlert.InfoAlert("更新成功")
            }else {
                sysAlert.WarningAlert(res.msg)
            }
            dispatch({type: AppActionType.setCurrentUserAvatar, payload: res.data});          
        }else{
            sysAlert.WarningAlert("上传失败")
        }
    } catch (err) {
        console.log("操作失败", err.message, "error");
    }
};

export const updateImageAsync = async (param) =>  {
    const {fd} = param;    
    try{
        let url = `${fileHost}/api/admin/image`;
        const imageRes = await trackPromise(httpUtil.httpAsyncFormPost(url , fd ));
        if (imageRes.success) {
            return imageRes.data
        }else {
            sysAlert.WarningAlert(imageRes.msg)
            return null
        }
    } catch (err) {
        sysAlert.WarningAlert("服务器内部错误")
        return null        
    }
}


// 退出登录
export const adminLogout = () => async () => {
    localUtil.removeSessionStore(sysConst.LOGIN_ADMIN_ID);
    localUtil.removeSessionStore(sysConst.LOGIN_ADMIN_TYPE);
    localUtil.removeSessionStore(sysConst.AUTH_TOKEN);
    try {
        await httpUtil.httpPost(apiHost + '/admin/logout', {});
        
    } catch (err) {
        
    }
    window.location.href = '/login';
};

export const toggleTheme = () => async (dispatch,getState) => {
    const darkMode = getState().AppReducer.darkMode;
    if(darkMode==='dark'){
        dispatch({type: AppActionType.setToggleDarkMode, payload: 'light'});
    }else{
        dispatch({type: AppActionType.setToggleDarkMode, payload: 'dark'});
    }
}

export const getCaptcha = () => async (dispatch,getState) => {    
    try {
        const res = await httpUtil.httpGet(apiHost + '/public/captcha', {});
        console.log(res)
        if (res.success === true) {
            dispatch({type: AppActionType.setCaptchaId, payload: res.data.captchaId});
            dispatch({type: AppActionType.setCaptchaImage, payload: res.data.img});
        }else if (res.success === false) {
            sysAlert.WarningAlert(res.msg)
        }
    } catch(err){
        console.log(err)
        sysAlert.ErrorAlert(err.message)
    }
}