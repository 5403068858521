/* eslint-disable eqeqeq */
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');



export const getAsyncCmsTypeList = async (obj) =>{
    try{
        let url = apiHost + '/admin/cmsType?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const createCmsTypeAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/cmsType';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateCmsTypeAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/cmsType/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}



export const getAsyncCmsContentList = async (obj) =>{
    try{
        let url = apiHost + '/public/cmsContent?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncCmsContentDetail = async (params) =>{
    try{
        let url = apiHost + `/public/cmsContent/${params.id}`;
        
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const createCmsContentAsync = async(appObj) =>  {
    try {
        let url = apiHost + '/admin/cmsContent';
        appObj.status = 1 ; 
        let res = await trackPromise(httpUtil.httpPost(url, appObj));
        if(res.success){
            return res.data
        }else{
            return null
        }    
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

export const updateCmsContentAsync = async (params) =>  {
    try {
        let url = apiHost + `/admin/cmsContent/${params.id}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}

