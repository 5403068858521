import React, {useState,useEffect} from 'react';
import {connect} from 'react-redux';
import { Box, Divider,Stack,Typography} from '@mui/material';
import * as echarts from 'echarts';
import { getUserRegDayStat,getAsyncUserRegDayStat } from '../../store/actions/main/UserStatAction';

const UserStat = (props) => {
    const {queryUserRegDayStat,userStatReducer} = props;
    const [userRegDayXData , setUserRegDayXData] = useState([])
    const [userRegDayYData , setUserRegDayYData] = useState([])
    useEffect(()=>{
        getAsyncUserRegDayStat({total:30}).then(res => {
            let xData = [];
            let yData = [];
            for(let i =  res.length ;i>0;i--) {
                xData.push(res[i-1].id);
                yData.push(res[i-1].user_count);
            }
            const userDayStatBarChart = echarts.init(document.getElementById('user_day_stat_div'));
            // 绘制图表
            userDayStatBarChart.setOption({
                /* title: {
                    text: '最近30天用户注册数',
                    textAlign: 'left',
                    textVerticalAlign: 'top'
                }, */
                tooltip: {},
                xAxis: {
                    data: xData
                },
                yAxis: {},
                series: [
                    {
                    name: '注册',
                    type: 'bar',
                    data: yData
                    }
                ]
            });
        })
        
        
    },[])
    return (
        <Box style={{paddingTop:10}}>            
            <Typography  gutterBottom variant="h6" >用户统计</Typography>
            <Divider  style={{borderWidth:2,marginBottom:10}} /> 
            <Stack direction="column" alignItems="center">
                <Typography  gutterBottom variant="h6" >最近30日新增用户统计</Typography>
                <Box id="user_day_stat_div" style={{width:"100%",height:300}}></Box>
            </Stack>  
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        userStatReducer: state.UserStatReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    queryUserRegDayStat:()=>{dispatch(getAsyncUserRegDayStat())}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStat);